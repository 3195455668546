import axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router/index'

let dev = process.env.VUE_APP_DEV_API;
let baseURL = process.env.VUE_APP_BASE_API;
// 创建axios 实例
const instance = axios.create({
    baseURL: baseURL,
    timeout: 10000,
});

// 请求拦截器，可用于添加公共请求头等操作
instance.interceptors.request.use((config) => {
    // 判断有没有token
    const token = window.localStorage.getItem('token');
    if (token) {
        // 添加公共请求头
        config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('token');
    }else{
        console.error("token不存在");
    }
    return config;
});

// 响应拦截器，可用于处理错误等操作
instance.interceptors.response.use((response) => {
    if (response.data.code === 200) {
        Message.success({
            message: response.data.msg,
            type: "success",
            duration: 1000,
        });
        return response.data;
    } else {
        Message.error({
            message: response.data.msg,
            type: "error",
            duration: 3000,
        });
        // 判断是否是过期的token
        if (response.data.msg === "验证失败！jwt expired") {
            window.localStorage.removeItem('token');
            router.push('/login');
        }
        return response.data;
    }
}, (error) => {
    // 处理错误
    console.log(error);
    Message.error(error);
});

export default instance;
