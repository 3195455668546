import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import BasicLayout from '@/layout/BasicLayout.vue'
import NotFound from '@/views/NotFoundView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/system/main",
    component: BasicLayout,
    children: [
      {
        path: "/system/main",
        name: "数据中心",
        component: HomeView,
        meta: {
          icon: "el-icon-pie-chart",
          requiresAuth: true,
          title: "数据中心",
        },
      },
    ]
  },
  {
    path: '/system/account',
    name: '账号管理',
    component: BasicLayout,
    meta: {
      icon: 'el-icon-user-solid',
      requiresAuth: true,
      title: '账号管理',
    },
    children: [
      {
        path: '/system/userAccount',
        name: '账号编辑',
        component: () => import('@/views/Account/UserAccount.vue'),
        meta: {
          requiresAuth: true,
          title: '账号编辑',
        },
      }
    ]
  },
  {
    path: "/system/article",
    name: "文章管理",
    component: BasicLayout,
    meta: {
      icon: "el-icon-edit-outline",
      requiresAuth: true,
      title: "文章管理",
    },
    children: [
      {
        path: "/system/edit",
        name: "文章编辑",
        component: () => import("@/components/EditTable/BasicEditTable.vue"),
        meta: {
          icon: "el-icon-document",
          title: "文章编辑",
          requiresAuth: true,
        }
      },
    ],
  },
  {
    path: "/log",
    name: "日志管理",
    meta: {
      icon: "el-icon-s-platform",
    },
    component: BasicLayout,
    children: [
      {
        path: "/log/access",
        name: "访问日志",
        component: () => import('../views/Log/LogView.vue'),
        meta: {
          icon: "el-icon-s-data",
          requiresAuth: true,
          title: "日志访问管理",
        }
      },
    ],
  },
  // {
  //   path: '/system',
  //   component: Home,
  //   redirect: "payment",
  //   children: [
  //     {
  //       path: "/payment",
  //       name: "支付",
  //       component: () => import("../components/payment/PcPayment.vue"),
  //       meta: {
  //         icon: "el-icon-coin",
  //         requiresAuth: true,
  //         title: "测试",
  //       },
  //     },
  //   ]
  // },
  // {
  //   path: "/system",
  //   component: Home,
  //   redirect: "authority",
  //   children: [
  //     {
  //       path: "/authority",
  //       name: "权限",
  //       component: () => import("../components/Authority/Admin.vue"),
  //       meta: {
  //         icon: "el-icon-coordinate",
  //         requiresAuth: true,
  //         title: "测试",
  //       },
  //     },
  //   ]
  // },
  // {
  //   path: "/system",
  //   component: Home,
  //   redirect: "/system/setting",
  //   children: [
  //     {
  //       path: "/system/setting",
  //       name: "设置",
  //       component: () => import("../components/settings/index"),
  //       meta: {
  //         icon: "el-icon-set-up",
  //         requiresAuth: true,
  //         title: "测试",
  //       },
  //     },
  //   ]
  // },
  // {
  //   path: "/chart",
  //   name: "图表",
  //   meta: {
  //     icon: "ri-slideshow-line",
  //   },
  //   component: Home,
  //   children: [
  //     {
  //       path: "/chart/test",
  //       name: "测试定制图表",
  //       component: chart,
  //     },
  //   ],
  // },
  {
    path: "/login",
    name: "登录",
    component: LoginView,
    isHiddenRouteInMenu: true,
    meta: { requiresAuth: false },
  },
  {
    path: "*",
    name: "404",
    isHiddenRouteInMenu: true,
    component: NotFound,
    meta: { requiresAuth: false }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


function isLoggedIn() {
  const token = window.localStorage.getItem('token');
  if (token) {
    return true;
  }
  // 否则，用户未登录
  return false;
}



// 添加路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    // 判断是否有有token
    if (isLoggedIn()) {
      next();
    } else {
      next('/login') // 如果需要登录且用户未登录，则重定向到登录页面
    }
  } else {
    next() // 否则，允许访问目标路由
  }
})

router.afterEach(
  (to, from) => {
    // 设置标题
    document.title = to.meta.title || '通用管理后台'
  }
)



export default router
