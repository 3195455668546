<template>
  <div class="echarts">


    <chart :option="option"/>


  </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/lines";

export default {
  name: "BasicArea",
  components: {
    chart: ECharts,
  },
  props: {},
  data() {
    return {
      option: {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: 'line',
          areaStyle: {}
        }]
      }
    };
  },
  methods: {},
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 23rem;
}
</style>
