<template>
  <div>
    <base-card :title="titles"></base-card>
    <WeatherCard>
      <template #weather>
        <TemperatureChart></TemperatureChart>
      </template>
    </WeatherCard>
    <TwoPagesCard>
      <template #adAnalyse>
        <ring-chart></ring-chart>
      </template>
      <template #info>
        <AccessingInformationCharts></AccessingInformationCharts>
      </template>
    </TwoPagesCard>
    <!-- <TwoPagesCard><animation-delay></animation-delay></TwoPagesCard> -->
  </div>
</template>

<script>
import BaseCard from "@/components/Card/BaseCard";
import RingChart from "@/components/Charts/RingChart";
import AnimationDelay from "@/components/Charts/AnimationDelay";
import StackedAreaChart from "@/components/Charts/StackedAreaChart.vue";
import TemperatureChart from "@/components/Charts/TemperatureChart.vue";
import BaseAreaChart from "@/components/Charts/BaseAreaChart.vue";
import WeatherCard from "@/components/Card/WeatherCard.vue";
import TwoPagesCard from "@/components/Card/TwoPagesCard.vue";
import InfoCard from "@/components/Card/InfoCard.vue";
import AccessingInformationCharts from "@/components/Charts/AccessingInformationCharts.vue";
export default {
  name: "Main",
  components: { AccessingInformationCharts, AnimationDelay, RingChart, BaseCard, StackedAreaChart, TemperatureChart, BaseAreaChart, WeatherCard, TwoPagesCard, InfoCard },
  data() {
    return {
      titles: ['文章数据', '书籍数据', '收藏数据', '访问数据']
    }
  },
  props: {

  },
  mounted() {

  }
}
</script>

<style scoped></style>
