<template>
  <div class="echarts" :key="key">
    <chart class="chart" :option="option" />
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/lines";
import { getWeather } from "@/utils/api";
import { Message } from "element-ui";

export default {
  name: "TemperatureChart",
  components: {
    chart: ECharts,
  },
  props: {},

  mounted() {
    this.$nextTick(() => {
      getWeather("58626").then((res) => {
        try{
          console.log(res.data);
          res.data.daily.forEach((element, index) => {
            this.option.xAxis.data[index] = element.date;
            this.option.series[0].data[index] =
            {
              value: element.high,
              symbol: 'image://https://weather.cma.cn/static/img/w/icon/w' + element.dayCode + '.png',
              symbolSize: [60, 60]
            };
            this.option.series[1].data[index] = {
              value: element.low,
              symbol: 'image://https://weather.cma.cn/static/img/w/icon/w' + element.nightCode + '.png',
              symbolSize: [60, 60]
            };
            this.option.series[2].data[index] = element.dayText;
          });
          // console.log(this.option.series[0].data);
          this.option.yAxis.max = Math.max(...this.option.series[0].data)
          this.option.yAxis.min = Math.min(...this.option.series[1].data)
          // 最近更新时间
          this.option.title.subtext = "最近更新：" + res.data.lastUpdate
          this.key = 2;
        }catch(e){
          Message.error("获取天气失败，请检查网络连接")
        }
      });
    });
  },
  data() {
    return {
      key: 1,
      option: {
        darkMode: true,
        title: {
          text: "七天天气大数据",
          subtext: "数据更新中...",
          padding: [5, 0, 0, 20],
          textStyle: {
            color: "white",
          },
        },
        tooltip: {
          trigger: "axis",
          show: true
        },
        textStyle: {
          color: '#fff'
        },
        toolbox: {
          show: true,
          feature: {
            // dataZoom: {
            // 	yAxisIndex: "none",
            // },
            // dataView: { readOnly: false },
            magicType: { type: ["line", "bar"] },
            // restore: {},
            // saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: "value",
          boundaryGap: false,
          min: 0,
          max: 100,
          axisLabel: {
            formatter: "{value} °C",
          },
          axisLine: {
            // 是否显示边界线
            show: true
          },
          splitLine: {
            // 是否显示横纵坐标的网格线
            show: false
          }
        },
        legend: {
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            name: "最高温度",
            type: "line",
            data: [],
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
            smooth: true
          },
          {
            name: "最低温度",
            type: "line",
            data: [],
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
            smooth: true
          },
          {
            name: "天气",
            type: "line",
            data: [],
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
            smooth: true
          },
        ],
      },
    };
  },
  methods: {},
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 23rem;
}

.chart {
  width: 100%;
  height: 23rem;
}
</style>
