<template>
  <div class="echarts">
    <chart :option="option"/>
  </div>
</template>

<script>
// import 'echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import ECharts from "vue-echarts";
let xAxisData = [
  'Bitcoin',
  'Ethereum',
  'Binance',
  'XRP',
  'Tether',
  'Cardano',
  'Polkadot',
  'Uniswap',
  'Litecoin',
  'Chainlink',
  'Stellar',
  'BitcoinCash',
  'THETA',
  'Filecoin',
  'USDCoin',
  'Dogecoin',
  'TRON',
  'DigiByte',
  'PundiX',
  'VoyagerToken',
  'Fantom',
  'Revain',
  'ReserveRights',
  'Ankr',
  'Ren',
  'BTMX',
  'SwissBorg',
  'Celo',
  'ConfluxNetwork',
  'IOST',
  'PaxosStandard',
]
const data1 = [];
let data2 = [];
for (let i = 0; i < 31; i++) {
  // xAxisData.push('类目' + i);
  data1.push((Math.sin(i / 5) * (i / 5 -10) + i / 6) * 5);
  data2.push((Math.cos(i / 5) * (i / 5 -10) + i / 6) * 5);
}
export default {
  name: "AnimationDelay",
  components: {
    'chart': ECharts
  },
  data() {
    return {
      option: {
        legend: {
          left: 'center',
          data: [
            {
              name: 'bar',
              icon: 'roundRect',
              textStyle: {
                color: '#fdf6e3'
              }
            },
            {
              name: 'bar2',
              icon: 'roundRect',
              textStyle: {
                color: '#fdf6e3'
              }
            },
          ]
        },
        tooltip: {},
        xAxis: {
          data: xAxisData,
          splitLine: {
            show: false
          }
        },
        yAxis: {},
        series: [{
          name: 'bar',
          type: 'bar',
          data: data1,
          emphasis: {
            focus: 'series'
          },
          animationDelay: function (idx) {
            return idx * 10;
          }
        }, {
          name: 'bar2',
          type: 'bar',
          data: data2,
          emphasis: {
            focus: 'series'
          },
          animationDelay: function (idx) {
            return idx * 10 + 100;
          }
        }],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      }
    }
  }
}
</script>

<style scoped>
.echarts{
  width: 100% !important;
  height: 23rem;
}
</style>
