<template>
	<el-row :gutter="12">
		<el-col :span="6" v-for="(item, index) in projectData" :key="index">
			<el-card>
				<div class="title">
					<span style="font-weight: bold; text-align: center">{{
						item.title
					}}</span>
				</div>

				<div
					class="data"
					style="text-align: center; font-size: 24px; color: #ecfbff"
				>
					<span>{{ item.number }}</span>
				</div>
			</el-card>
		</el-col>
	</el-row>
</template>

<script>
import "animate.css";
import {
	getBookAllCount,
	getUserAccountCount,
	getVisitCount,
} from "@/utils/api";
import { Message } from "element-ui";
export default {
	name: "BaseCard",
	props: {
		title: null,
	},
	data() {
		return {
			projectData: [
				{
					title: "访问人数（单位：人）",
					number: 0,
				},
				{
					title: "账户数量（单位：个）",
					number: 0,
				},
				{
					title: "书籍数量（单位：本）",
					number: 0,
				},
				{
					title: "文章数据（单位：篇）",
					number: 0,
				},
			],
		};
	},
	mounted() {
			// 请求数据对projectData进行赋值操作
			getVisitCount().then((res) => {
				this.projectData[0].number = res.data.count;
			});
			getUserAccountCount().then((res) => {
				try {
					this.projectData[1].number = res.data.count;
				} catch (error) {
					Message.error("获取用户账户数量失败！", error);
				}
			});
			getBookAllCount().then((res) => {
				this.projectData[2].number = res.data.count;
			});
	},
};
</script>

<style scoped>
.data {
	width: 100%;
	height: 5rem;
	margin: 0 auto;
	background: #1f2240;
	line-height: 5rem;
	border-radius: 1rem;
}

.title {
	width: 98%;
	height: 2rem;
	margin: 0 auto;
	border-bottom: 2px solid #aff1e4;
	text-align: center;
	margin-bottom: 1rem;
}

.el-card {
	height: 10rem;
	background: #2a2f55;
	border: 0;
	color: #d7d8dd;
}

@keyframes border-animation {
	0% {
		width: 1%;
		position: relative;
		left: 50%;
	}

	50% {
		width: 50%;
		position: relative;
		left: 0;
	}
}
</style>
