<template>
  <el-row class="row">
    <el-col :span="24">
      <div class="e-card">
        <slot></slot>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "LongLattice",
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.row{
  width: 100%;
  height: 32.5%;
  background: #2a2f55;
  border: 0;
  border-radius: 19px;
}
.row:not(:last-of-type){
  margin-bottom: 0.8rem;
}
</style>
