<template>
	<el-row :gutter="12" class="e-card">
		<el-col :span="12">
			<div class="e-left-card">
				<big-lattice>
					<slot name="adAnalyse"></slot>
				</big-lattice>
			</div>
		</el-col>

		<el-col :span="12">
			<div class="e-right-card">
				<big-lattice>
					<slot name="info"></slot>
				</big-lattice>
			</div>
		</el-col>
	</el-row>
</template>

<style scoped>
    .e-card {
        width: 100%;
        height: 24rem;
        /* background: #2a2f55; */
        border: 0;
        color: #d7d8dd;
        border-radius: 19px;
        margin: 1rem;
    }
    .e-right-card {
        width: 100%;
        height: 23rem;
        color: #d7d8dd;
        border-radius: 19px;
    }
    </style>

<script>
import BigLattice from "@/components/Card/BigLattice";
import LongLattice from "@/components/Card/LongLattice";

export default {
	name: "TwoPagesCard",
	components: { LongLattice, BigLattice },
	props: {
	},
	data(){
		return {
			weatherTitle: "",
			seriesData: []
		}
	},
	mounted(){
		
	}
};
</script>


