//初始化时用sessionStore.getItem('token'),这样子刷新页面就无需重新登录
const state = {

    token: window.sessionStorage.getItem('token'),
    username: window.sessionStorage.getItem('username')

};

const mutations = {

    LOGIN: (state, data) => {

        //设置token的值
        state.token = data;
        // 将token的值存入到缓存中
        window.sessionStorage.setItem('token', data);
    },

    LOGOUT: (state) => {

        //登出的时候要清除token
        state.token = null;
        // 从磁盘中移除token值
        window.sessionStorage.removeItem('token');

    },

    USERNAME: (state, data) => {

        //把用户名存起来
        state.username = data;
        window.sessionStorage.setItem('username', data);
    }

};

const actions = {

    UserLogin({commit}, data) {

        commit('LOGIN', data);

    },

    UserLogout({commit}) {

        commit('LOGOUT');

    },

    UserName({commit}, data) {

        commit('USERNAME', data);

    }

};

const getters = {
    userName: function () {
        return '测试成功'
    }

}

export default {
    namespaced: true,
    state,

    mutations,

    actions,

    getters

}
