<template>
  <div class="echarts">
    <chart :option="option"/>
  </div>
</template>

<script>
// import 'echarts'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/chart/pie'

export default {
  name: "RingChart",
  components: {
    'chart': ECharts
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item',
          show: true
        },
        legend: {
          top: '5%',
          bottom: '50%',
          left: 'center',
          data: [
            {
              name: '头条广告',
              icon: 'roundRect',
              textStyle: {
                color: '#fdf6e3'
              }
            },
            {
              name: '邮件广告',
              icon: 'roundRect',
              textStyle: {
                color: '#fdf6e3'
              }
            },
            {
              name: '联盟广告',
              icon: 'roundRect',
              textStyle: {
                color: '#fdf6e3'
              }
            },
            {
              name: '视频广告',
              icon: 'roundRect',
              textStyle: {
                color: '#fdf6e3'
              }
            },
            {
              name: 'Google广告',
              icon: 'roundRect',
              textStyle: {
                color: '#fdf6e3'
              }
            }
          ]
        },
        series: [
          {
            name: '广告收入',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold',
                color: '#fff'
              }
            },
            labelLine: {
              show: true
            },
            data: [
              {value: 1048, name: '头条广告'},
              {value: 735, name: '邮件广告'},
              {value: 580, name: '联盟广告'},
              {value: 484, name: '视频广告'},
              {value: 300, name: 'Google广告'}
            ]
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 23rem;
}

/* canvas {
  position: absolute !important;
  top: 1rem !important;
  color: #f2f2f2 !important;
} */
</style>
