/* 
 Create By Zevin
*/

import CryptoJS from 'crypto-js';

// 加密密钥
// 生成随机密钥
const CryptoSecret = "soideiweweucwnchimgwxm827tsdcei93d283qmc7hf789QFK7qm7M7FEIMIE843398ID4387Y5OKDEWKTG59K8"

/**
 * 加密数据
 * @param data - 数据
 * @description AES加密
 */
export function encrypt(data) {
    const newData = JSON.stringify(data);
    return CryptoJS.AES.encrypt(newData, CryptoSecret).toString();
}

/**
 * 解密数据
 * @param cipherText - 密文
 * @description AES解密
 */
export function decrypt(cipherText) {
    const bytes = CryptoJS.AES.decrypt(cipherText, CryptoSecret);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    if (originalText) {
        return JSON.parse(originalText);
    }
    return null;
}
