<template>
	<el-row :gutter="12" class="e-card">
		<el-col :span="24">
			<div class="e-right-card">
				<big-lattice>
					<slot name="weather"></slot>
				</big-lattice>
			</div>
		</el-col>
	</el-row>
</template>

<script>
import BigLattice from "@/components/Card/BigLattice";

export default {
	name: "WeatherCard",
	components: { BigLattice },
	props: {
	},
	data(){
		return {
			weatherTitle: "",
			seriesData: []
		}
	},
	mounted(){
		
	}
};
</script>

<style scoped>
.e-card {
	width: 100%;
	height: 24rem;
	/* background: #2a2f55; */
	border: 0;
	color: #d7d8dd;
	border-radius: 5px;
	margin: 1rem;
}

.e-right-card {
	width: 100%;
	height: 23rem;
	color: #d7d8dd;
	border-radius: 5px;
}
</style>
