import axiosInstance from "./axiosInstance";
import { encrypt, decrypt } from "./cryptoApi";

// 密码登录
export async function login_post(params) {
    params.action = "login";
    params = encrypt(params);
    console.log(params);
    return await axiosInstance({
        url: "/login",
        method: "post",
        data: params
    });
}

// 每次刷新检查登录的状态
export async function checkLoginStatus(token) {
    return axiosInstance.get('/login', {
        headers: {
            "Content-Type": "application/json",
        },
        params: {
            action: "checkLoginStatus",
            token: token,
        },
    })
}

// 获取当前用户浏览数量
export async function getVisitCount() {
    return axiosInstance.get('/lz', {
        headers: {
            "Content-type": "application/json",
        },
        params: {
            action: "visitCount",
        },
    });
}


/**
 * 获取blog日志浏览数据
 * @date 10/4/2023 - 12:15:43 AM
 *
 * @export
 * @async
 * @param {*} currentPage
 * @returns {unknown}
 */
export async function getVisitLogData(currentPage) {
    return axiosInstance.get("/lz", {
        headers: {
            "Content-type": "application/json",
        },
        params: {
            action: "VistLogData",
            currentPage: currentPage
        },
    });
}


// 获取当前用户数量
export async function getUserAccountCount() {
    let params = {
        action: "userAccountCount",
        role: "customer",
    };
    params = encrypt(params);
    return axiosInstance({
        url: "/user",
        method: "GET",
        headers: {
            "Content-type": "application/json",
        },
        params: {
            params
        },
    });
}

// 获取图书数量
export async function getBookAllCount() {
    return axiosInstance({
        url: "/book",
        method: "GET",
        headers: {
            "Content-type": "application/json",
        },
        params: {
            action: "doubanBookAllCount",
        },
    });
}


/**
 * 获取当前经纬度
 * @date 10/4/2023 - 12:16:29 AM
 *
 * @param {*} position
 */
function showPosition(position) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    alert('您的位置为：' + latitude + ',' + longitude);
}

// 请求天气接口
export async function getWeather(params) {
    // navigator.geolocation.getCurrentPosition(showPosition);
    return axiosInstance.get(
        "/weather", {
        params: {
            action: "queryWeather",
            stationid: params,
        }
    }
    );
}


/**
 * 获取blog数据
 * @date 10/4/2023 - 12:16:56 AM
 *
 * @export
 * @async
 * @returns {unknown}
 */
export async function getBlogs() {
    return axiosInstance({
        url: "/blog",
        method: "GET",
        headers: {
            "Content-type": "application/json",
        },
        params: {
            action: "getBlogs",
        },
    })

}


export async function writeBlog(params) {
    params.action = "writeBlog";
    params = encrypt(params);
    return axiosInstance({
        url: "/blog",
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        data: params,
    })
}

export async function deleteBlog(params) {
    params.action = "deleteBlog";
    params = encrypt(params);
    return await axiosInstance({
        url: "/blog",
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        data: params,
    })
}

export async function updateBlog(params) {
    params.action = "updateBlog";
    // 加密参数
    params = encrypt(params);
    return axiosInstance({
        url: "/blog",
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        data: params,
    })
}

export async function getAllAccountInfo(params) {
    console.log(params);
    params.action = "userAccount";
    params = encrypt(params);
    return axiosInstance({
        url: "/user",
        method: "GET",
        headers: {
            "Content-type": "application/json",
        },
        params: {
            params
        },
    })
}

export async function deleteAccount(params) {
    params.action = "deleteAccount";
    params = encrypt(params);
    return axiosInstance({
        url: "/user",
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        data: params,
    })
}

export async function updateAccount(params) {
    params.action = "updateAccount";
    params = encrypt(params);
    return axiosInstance({
        url: "/user",
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        data: params,
    })
}