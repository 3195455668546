<template>
  <el-row>
    <el-col :span="24">
      <el-menu :default-active="onRoutes" background-color="#24253c" class="el-menu-vertical"
        style="width: 11.5rem; height: 100%; position: fixed;" :router="true">
        <!-- 普通菜单 -->
        <div v-for="(item, index) in obtainRoute" :key="index">
          <template v-if="!item.isHiddenRouteInMenu">
            <el-submenu :index="item.path" v-if="item.name">
              <!-- 一级菜单 -->
              <template slot="title">
                <div >
                  <i :class="Object(item.meta).icon" style="margin-right: 1rem; font-size: 1.3rem"></i>
                  <span slot="title" style="font-weight: bold; color: #b3c0d1;">{{ item.name }}</span>
                </div>
              </template>
              <!-- 二级菜单 -->
              <el-menu-item :index="cItem.path" v-for="(cItem, cIndex) in item.children" :key="cIndex">
                <i :class="Object(cItem.meta).icon" style="margin-right: 1rem; font-size: 1.4rem"></i>
                <span slot="title" style="font-weight: bold">{{
                  cItem.name
                }}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item :index="item.children[0].path" v-else>
              <i :class="Object(item.children[0].meta).icon" style="margin-right: 1.2rem; font-size: 1.7rem"></i>
              <span slot="title">{{ item.children[0].name }}</span>
            </el-menu-item>
          </template>
        </div>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "Menu",
  components: {},
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      testData: [],
    };
  },
  computed: {
    obtainRoute() {
      return this.$router.options.routes;
    },
    onRoutes() {
      return this.$route.path;
    },
  },
  mounted() {
    this.onRoutes;
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
.el-menu {
  border-right: none;
  margin-top: 0.5rem;
}

.el-submenu,
.el-menu-item {
  /* background: linear-gradient(to bottom,
      #0f0156,
      #0e0354,
      #0e0b45,
      #0c0c45,
      #0f054d); */
  border-bottom: 1px solid #0f0156;
  min-width: 0 !important;
}

.el-menu-item,
.el-menu-item-group__title,
.el-submenu__title {
  background: #0e0b45;
  color: #b3c0d1 !important;
  box-shadow: 1px 1px 1px #24253c, -1px -1px 1px #24253c;
  border-radius: 6px;
}

.el-submenu__title:hover {
  color: #ffffff !important;
}

.el-menu-item:hover {
  background-color: rgba(121,134,203, 0.3)  !important;
  color: #ffffff !important;
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
}


.is-active {
  color: #7986cb !important;
  font-weight: bold;
}

.el-menu {
  border: 0 !important;
}

.link {
  text-decoration: none;
  color: #000;
}
</style>
