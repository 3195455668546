<template>
	<div id="home">
		<el-container style="height: 500px; border: 1px solid #2d3038">
			<!--头部部分-->

			<el-header class="header" style="
					width: 100%;
					height: 4rem;
					display: flex;
					justify-content: space-between;
					position: fixed;
					z-index: 20;
					border-bottom: 1px solid #24253c;
				">
				<div style="
						text-align: left;
						color: #fff;
						vertical-align: middle;
						padding: 0.3rem 0;
					">
					<el-image :src="systemIcon" fit="fit" style="
							width: 2rem;
							height: 2rem;
							margin-right: 0.6rem;
							margin-top: 0.8rem;
							display: inline-block;
							background-color: #fff;
							border-radius: 5px;
							padding: 5px;
							border: 1px solid #2d3038;
							float: left;
						" @click="closeMenu">
					</el-image>

					<span class="sub-title">A-PLATFORM</span>

					<span style="margin-left: 20px; font-size: 20px;" @click="closeMenu">
						<i class="el-icon-s-fold" v-if="isCollapsed"></i>
						<i class="el-icon-s-unfold" v-if="!isCollapsed"></i>
					</span>
				</div>

				<div v-if="search">
					<el-input></el-input>
				</div>

				<div class="header-right">
					<div style="
							margin-right: 0;
							display: inline-block;
							vertical-align: middle;
						">
						<i class="el-icon-message" style="color: #ffffff; font-size: 1.2em"></i>
					</div>

					<div class="search" style="
							margin: 0 2rem 0 1rem;
							display: inline-block;
							vertical-align: middle;
						" @click="showSearch">
						<i class="el-icon-search" style="font-size: 1.2rem; color: #fff"></i>
					</div>

					<el-dropdown trigger="hover">
						<span class="user-info">
							<el-avatar :src="avatar" :size="36"></el-avatar>
							<span class="name">{{ username }}</span>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</el-header>

			<el-container style="margin-top: 3.75rem">
				<!-- 菜单栏 -->
				<transition name="fade">
					<Menu v-if="isCollapsed" style="width: 13rem;"></Menu>
				</transition>
				<!--内容主题部分-->
				<el-main style="width: 100%; padding: 0.5rem;">
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>
<style scoped>
#home {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.info {
	width: auto;
	height: fit-content;
}

.user-info {
	display: flex;
	align-items: center;
}

.name {
	margin-left: 8px;
	color: #eeeeee;
	font-weight: 600;
}

.sub-title {
	font-family: ZCOOLKuaiLe, serif;
}

.el-container,
.is-vertical {
	border: 0 !important;
	background: #1c2141;
}

.el-submenu__title {
	background-color: #7986cb !important;
}




.is-active {
	/* background: #7986cb !important; */
	color: #ffffff !important;
}

.el-menu-item span {
	color: #ffffff;
	/* font-size: 1rem; */
	font-family: "Noto";
}

.el-header {
	color: #333;
	line-height: 60px;
}

.el-aside {
	height: 100vh;
	color: #333;
	margin-top: 0.25rem;
}

.header {
	background: #24253c;
}

.search:hover {
	color: skyblue;
	cursor: pointer;
}

.el-dropdown-link {
	margin-top: 5px !important;
}

.header-right {
	display: flex;
	flex-direction: row;
	align-items: center;
}



.fade-enter-active {
	animation: fadeInRight;
	/* referring directly to the animation's @keyframe declaration */
	animation-duration: 0.5s;
	/* don't forget to set a duration! */
}

.fade-leave-active {
	animation: fadeOutLeft;
	/* referring directly to the animation's @keyframe declaration */
	animation-duration: 0.5s;
	/* don't forget to set a duration! */
}
</style>

<script>
import Menu from "../components/Menu/Menu.vue";

export default {
	name: "Home",
	components: { Menu },
	data() {
		return {
			avatar: "",
			username: this.setName,
			squareUrl:
				"http://icon.chrafz.com/uploads/allimg/170119/1-1F1191340000-L.png",
			systemIcon:
				"http://icon.chrafz.com/uploads/allimg/170119/1-1F1191340000-L.png",
			isCollapsed: true,
			search: false
		};
	},
	mounted() {
		this.username = this.setName;
	},
	methods: {
		closeMenu() {
			// const elMenuCollapse =
			// 	document.getElementsByClassName("el-menu--collapse");
			this.isCollapsed = !this.isCollapsed;
			// if (elMenuCollapse.length !== 0) {
			// 	const width = elMenuCollapse[0].style.width;
			// 	console.log("width:" + width);
			// }
		},
		showSearch() {
			this.search = !this.search;
		},
		logout() {
			window.localStorage.removeItem("username");
			window.localStorage.removeItem("avatar");
			window.localStorage.removeItem("token");
			this.$router.push("/login");
		},
		getUserInfo() {
			this.username = window.localStorage.getItem("username");
		},
		getUserAvatar() {
			this.avatar = window.localStorage.getItem("avatar");
		}
	},
	computed: {
		setName() {
			return window.localStorage.getItem("username");
		},
	},
};
</script>
