<template>
    <el-container>
        <div class="container">
            <el-row>
                <el-col>
                    <el-form ref="formRules" :rules="rules" :model="loginForm" class="login" label-width="65px"
                        v-loading="loading" element-loading-text="别着急啊，等会儿就登陆了" element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)">
                        <h3 style="margin: 2rem 0; text-shadow: 1px 1px 1px #f6f6f6;">
                            <span style="color: #f6f6f6; font-size: 30px; margin-right: 15px;text-align: center;">
                                <i class="el-icon-s-tools"></i>
                            </span>
                            <span style="color: #f6f6f6; font-size: 30px;">Login</span>
                        </h3>
                        <el-form-item label="用户名" prop="username" class="el-label">
                            <el-input type="text" auto-complete="true" v-model="loginForm.username"
                                placeholder="请输入用户名"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password" class="el-label">
                            <el-input type="password" :inline-message="true" auto-complete="false"
                                v-model="loginForm.password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <!-- style="width: 66%; height: 50%; display: inline-block; vertical-align: center;" -->
                        <el-form-item label="验证码" class="checkCode el-label" prop="code">
                            <!-- <el-row>
                                <el-col :span="12">
                                    <el-input type="text" v-model="loginForm.code" placeholder="验证码">
                                    </el-input>
                                </el-col>
                                <el-col :span="12">
                                    <captcha style="margin: 0 auto;" :check-code="loginForm.code"
                                        @transportCode="getValidValue" ref="captcha"></captcha>
                                </el-col>
                            </el-row> -->
                            <form v-if="showCaptcha" id="myForm" action="?" method="post">
                                <!-- 其他表单字段... -->
                                <div @click="onSubmit" class="g-recaptcha"
                                    data-sitekey="6LdHwiEpAAAAAN_9a6NUdkp2CKP0ijBmDGBRNxKI"
                                    >
                                </div>
                                <!-- <div @click="onSubmit" class="g-recaptcha"
                                    data-sitekey="6LfE3SEpAAAAABwbZkrTO6t7A9LL-nYxRcZCI_H5">
                                </div> -->
                                <el-button @click="onSubmit" native-type="submit" plain>点击验证</el-button>
                            </form>
                            <br />
                        </el-form-item>
                        <el-checkbox id="remember" v-model="check" class="checkbox el-label" @change="
                            rememberMe({
                                username: loginForm.username,
                                password: loginForm.password,
                            })
                            ">记住我</el-checkbox>
                        <el-button :disabled="btnState" type="primary" style="width: 100%; margin-bottom: 20px;"
                            @click="submitForm('formRules')">登录
                        </el-button>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </el-container>
</template>

<script lang="js">
import { login_post } from "@/utils/api";
import Captcha from "@/components/Captcha/Captcha";
import { Message } from "element-ui";
import { encrypt } from "@/utils/cryptoApi.js";
import router from "@/router/index";
export default {
    name: "Login",
    components: { Captcha },
    data() {
        return {
            loading: false,
            btnState: false,
            contain: "fit",
            loginForm: {
                username: undefined,
                password: undefined,
                code: "",
                value: " ",
            },
            check: false,
            rules: {
                username: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "点击验证",
                        trigger: "blur",
                    },
                ],
                loading: false,
            },
            showCaptcha: true
        };
    },
    mounted() {
        // 当用户选这个记住我这个复选框，将会存储用户名和密码，因此我们在mounted的时候检查并将其拿出
        let username = window.localStorage.getItem("username");
        let password = window.localStorage.getItem("password");
        if (
            username !== null &&
            username !== undefined &&
            password !== null &&
            password !== undefined
        ) {
            this.check = true;
            this.loginForm.username = username;
            this.loginForm.password = password;
        } else {
            console.log(username);
        }
    },
    beforeMount() {
    },
    updated() {
    },
    methods: {
        // getValidValue(msg) {
        //     // 接收子组件生成并传递过来的验证码
        //     // console.log("msg", msg);
        //     this.loginForm.value = msg.toLowerCase();
        //     // console.log("子组件传的值：" + this.loginForm.value.toLowerCase());
        // },
        // validateCode() {
        //     console.log("验证码", this.loginForm.value, this.loginForm.code);
        //     if (this.loginForm.code !== this.loginForm.value) {
        //         this.$message.error("验证码错误。");
        //         return false;
        //     }
        //     return true;
        // },
        submitForm(formName) {
            this.loading = true;
            this.$refs[formName].validate(async (valid) => {
                // 验证码进行验证
                // if (valid) {
                this.btnState = true;
                try {
                    login_post({
                        username: this.loginForm.username,
                        password: encrypt(this.loginForm.password),
                        code: this.loginForm.code
                    }).then((res) => {
                        this.loading = false;
                        // 这个贼不安全，还需要进一步改善
                        if (res.code === 200) {
                            if (
                                res.data.username ===
                                this.loginForm.username
                            ) {
                                this.$store.commit(
                                    "user/LOGIN",
                                    res.data.token
                                );
                                this.$store.commit(
                                    "user/USERNAME",
                                    res.data.username
                                );
                                window.localStorage.setItem("token", res.data.token);
                                router.push("/system/main");
                            }
                        } else {
                            Message.error({
                                message: "登陆失败,请刷新重新登陆！",
                                type: "error",
                                duration: 5000
                            });
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000)
                        }
                    });
                } catch (e) {
                    this.loading = false;
                }
                // 当登陆成功之后在登陆跳转后的页面提示登陆成功
                // } else {
                //     this.loading = false;
                //     Message.error("请点击验证码进行验证")
                //     return false;
                // }
            });
        },
        rememberMe(params) {
            if (
                params.hasOwnProperty("username") &&
                params.hasOwnProperty("password")
            ) {
                window.localStorage.setItem("username", params.username);
                window.localStorage.setItem("password", encrypt(params.password));
            } else {
                window.alert("请输入用户名和密码！");
            }
        },
        onSubmit() {
            let myForm = document.getElementById('myForm');
            let that = this;
            myForm.addEventListener('submit', function (event) {
                // 阻止默认的表单提交行为
                event.preventDefault();

                try {
                    // 获取 reCAPTCHA 复选框的响应值
                    let recaptchaResponse = document.getElementById('g-recaptcha-response').value;
                    that.loginForm.code = recaptchaResponse;
                    if (that.loginForm.code !== "") {
                        Message.success("验证码验证成功！请点击登录");
                    } else {
                        Message.error("请输入验证码！");
                    }
                } catch (error) {
                    console.log(error);
                    Message.error("请刷新页面");
                }
            });
        }
    },
};
</script>

<style>
.container {
    width: 100%;
    height: 100vh;
    background-image: url("@/assets/images/background.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.login {
    width: 500px;
    color: white;
    position: fixed;
    top: 50%;
    left: 70%;
    padding: 15px 50px 100px 50px;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    background-color: rgba(223, 223, 223, 0.2);
    backdrop-filter: blur(100px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.checkbox {
    margin-bottom: 20px;
    margin-left: 15px;
}

h3 {
    text-align: center;
}

.el-label .el-form-item__label {
    color: white;
}

@media screen and (max-width: 768px) {
    .login {
        width: 80%;
        /*background-color: #5a6d65;*/
    }

    #code {
        width: 56%;
    }
}
</style>