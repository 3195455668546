<template>
  <el-row :gutter="12">
    <el-col :span="24">
      <div class="e-card" style="text-align: center;">
        <slot></slot>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "BigLattice"
}
</script>

<style scoped>
.e-card{
  width: 100%;
  height: 100%;
  padding: 0.5rem 0;
  background: #2a2f55;
  border: 0;
  border-radius: 19px;
}
</style>
