<template>
  <div class="echarts">
    <chart id="chart" :option="option" />
  </div>
</template>

<script>
import ECharts from "vue-echarts";
// import * as echarts from 'echarts/lib/echarts.js'
import 'echarts/lib/component/grid';
import 'echarts/lib/chart/line';

export default {
  name: "AccessingInformationCharts",
  components: {
    'chart': ECharts
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#f2f2f2'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          },
        },
        legend: {
          textStyle: {
            color: "#fff"
          },
          data: ['本周', '上周']
        },
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '浏览量',
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: '{value} 人'
            }
          },
          // {
          //   type: 'value',
          //   name: 'Temperature',
          //   min: 0,
          //   max: 25,
          //   interval: 5,
          //   axisLabel: {
          //     formatter: '{value} °C'
          //   }
          // }
        ],
        series: [
          {
            name: '本周',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' Views';
              }
            },
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
            ]
          },
          {
            name: '上周',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + 'Views';
              }
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
            ]
          },
          // {
          //   name: 'Temperature',
          //   type: 'line',
          //   yAxisIndex: 1,
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value + ' °C';
          //     }
          //   },
          //   data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
          // }
        ]
      }
    }
  },
  mounted() { }
}
</script>

<style scoped>
.echarts {
  width: 100% !important;
  height: 23rem;
}

#chart {
  width: 100%;
  height: 100%;
}
</style>