<template>
  <div class="captcha" @click="setCaptcha">
    <p id="p-a">q</p>
    <p id="p-b">3</p>
    <p id="p-c">2</p>
    <p id="p-d">a</p>
    <p id="p-e">h</p>
    <p id="p-f">g</p>
  </div>
</template>

<script>
export default {
  name: "Captcha",
  props: {
    checkCode: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      code: ''
    }
  },
  mounted() {
    this.setCaptcha()
  },
  updated() {
    this.setCaptcha()
  },
  methods: {
    setCaptcha() {
      let p = document.querySelectorAll('p')
      let alphabet = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      if (this.code !== '') {
        this.code = ''
        for (let i = 0; i < 6; i++) {
          //获取随机验证码下标
          let charNum = Math.floor(Math.random() * 62);
          //组合成指定字符验证码
          p[i].innerHTML = alphabet[charNum];
          this.code += alphabet[charNum]
        }
      } else {
        for (let i = 0; i < 6; i++) {
          //获取随机验证码下标
          let charNum = Math.floor(Math.random() * 62);
          //组合成指定字符验证码
          p[i].innerHTML = alphabet[charNum];
          this.code += alphabet[charNum]
        }
      }
      this.$emit('transportCode', this.code)
    },
  }
}
</script>

<style scoped>
.captcha {
  height: 40px;
  float: right;
}

p {
  margin: 0;
}

#p-a {
  display: inline-block;
  font-family: 'MedievalSharp', cursive;
  font-size: 25px;
  letter-spacing: 10px;
}

#p-b {
  display: inline-block;
  font-family: 'MedievalSharp', cursive;
  font-size: 26px;
  letter-spacing: 12px;
}

#p-c {
  display: inline-block;
  font-family: 'MedievalSharp', cursive;
  font-size: 25px;
  letter-spacing: 10px;
}

#p-d {
  display: inline-block;
  font-family: 'MedievalSharp', cursive;
  font-size: 26px;
  letter-spacing: 10px;
}

#p-e {
  display: inline-block;
  font-family: 'MedievalSharp', cursive;
  font-size: 25px;
  letter-spacing: 10px;
}

#p-f {
  display: inline-block;
  font-family: 'MedievalSharp', cursive;
  font-size: 26px;
  letter-spacing: 10px;
}
</style>
